a{
  text-decoration: none;
}
#csideSearch{
  width:100%;
  height: 10rem;
  /* box-shadow:0rem 0.25rem 0.5rem  #00000029; */
  display: grid;
  padding-left: 1rem;
  margin-top: 3.7rem;
  grid-template-columns: 1fr 2fr 0.5fr 2fr;
}
#checkout-btn{
  color:#FF6738;
  font-weight: bold;  
  align-self: center;
}
#check-Cart{
  display: grid;
  text-decoration: none;
  /* height:39rem; */
  /* grid-template-rows: 4fr 35fr 8fr; */
  /* padding-left: 1rem; */
  margin-right: 2rem;
}
#checkOut{
  border: none;
  text-align: center;
  /* margin-left: 0.5rem; */
  margin-right: 0.1rem;
  cursor: pointer;
  background-color:#ff6738;
  /* padding: 0.5rem 0.5rem; */
  font-size: 1rem;
  text-decoration: none;
  text-align: center;
  border-radius: 0.3rem;
  text-decoration: none;
  width: 100%;
}
  #check-Cheading{
    text-align: center;
    box-shadow: 0rem 0.2rem 0rem #00000029;
    opacity: 80%;
    font-weight: 600;
    width: 90%;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 2rem;
    font-weight: bolder;
    font-size: x-large;
    color: #02060a;
  }
  #check-Cdetails{
  
  /* font-size: larger; */
  padding:1rem;
  color: darkgray;
  font-style: italic;
  font-weight: bolder;
  width: 25rem;
  margin-bottom: 0rem;
  font-size: x-large;
    color: #02060a;

  }
  #check-TotalAmount{
    border: 0px;
    grid-auto-rows: 1fr 2fr;
    box-shadow: none;
    display: grid;
  }
  
  /* #check-total{
    font-size: x-large;
    padding-left: 1rem;
    font-weight: bolder;
    box-shadow: 0rem 0.25rem 0.5rem #00000029;
  } */
.MainBody2{
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 95rem;
  height: 70rem;
  display: grid;
  grid-template-columns: 3fr 1fr;
  border: 1px solid black;
  margin-left: 5rem;
  margin-right: 5rem;
}
#cd{
  display: flex;
  flex-direction: column;
  border: 0px;
}
#cd_menu{
 text-decoration: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  padding-left: 1rem;
}
#cd-text{
  font-weight: bold;
  letter-spacing: 0.1rem;
  border: 0px;
  flex-basis: 80%;
  font-size: large;
}


#checkOut{
  border: none;
  text-align: center;
  /* margin-left: 0.5rem; */
  margin-right: 0.1rem;
  cursor: pointer;
  background-color:#ff6738;
  /* padding: 0.5rem 0.5rem; */
  font-size: 1rem;
  text-decoration: none;
  text-align: center;
  border-radius: 0.3rem;
  text-decoration: none;
  width: 100%;
  height: 2rem;
  /* margin-top: 1rem; */
}

/* styling of profile page */

.profile{
  
  margin-right: 12rem;
  /* width: 76rem; */
  border: 0px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 12rem;
}

#P_img{
  /* background-image: url(./Components/assets/images/ Group\ 2.png); */
  
 
  margin-right: 1rem;
  height: 12rem;
  background-size: contain;
  width: 85%;
  margin-left: 1rem;
  background-image: url(./Components/assets/images/img1);
  background-repeat: no-repeat;
}

#Main{
  display: grid;
  grid-template-rows: 1fr 3fr 2fr 1fr 1.5fr 1fr ;
  
}

#p_names,#about,#interest-area,#stud,#info,#form-end{
  padding-left: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  height: 5rem;
  font-size: large;

}


#p_names{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: space-around;
 }
#name,#first-name,#last-name{
  width: 13rem;
}
#interest-area{
  width: 20%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  height: fit-content;
 }
 #interest {
   color:#030303;
   font-size: 1.1rem;
   height: 2rem;
 }
#about{
  width:60%;
}
#info{
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.input{
  font-weight: bolder;
  opacity: 0.5;
  /* font-size: 0.8rem; */
}

 #stud{
   display: grid;
   grid-template-rows: 1fr 1fr;
 }
 #opn{
  font-size: 1.1rem;
  height: 1.4rem;
 }
 #exp{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
 }
 #option{
   width: 10rem;
   height: 2.5rem;
   margin-top: 1rem;
  
 }
 #expertise{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
 }
 #ext-on{
  width: 10rem;
  height: 2.5rem;
  margin-top: 1rem;

  
 }

 #role{
   width:30%;
 }
 #rfeild{
    width:25rem;
    height: 1.8rem;
    margin-top: 2rem;
 }
/* INPUT STYPLING IN PROFILE PAGE  */
#name-input, #abt, #rfeild{
  box-sizing: border-box;
  border: none;
  background-color:#EFF2FF;
  color: #444f5a;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  padding-left: 1rem;
}

#name-input{
  width: 100%;
  height:1.5rem;
}
#abt{
  width: 100%;
  height:13rem;
  text-align: start;
}
#name-input{
  width: 75%;
  height:1.5rem;
}

#footer{
  justify-self: end;
  background-color: #FF6738;
  border-radius: 0.3rem;
  border: none;
  
  width: 4rem;

  height: 1.3rem;
  color: rgb(206, 185, 185);
  padding: 0.5rem 0.5rem;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.1rem;
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color:#FF6738;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='checkbox']:after {
  width: 15px;
  height: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='checkbox']:checked:after {
  width: 15px;
  height: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color:#FF6738;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
/* for styling of searchArea */
#search{
flex-basis: 90%;
  width: 100%;
  height:1.5rem;
  box-sizing: border-box;
  border: none;
  background-color:#EFF2FF;
  color: #444f5a;
  margin-left: -0.8rem;
}
#search-icon{
  flex-basis: 10%;
  background-image: url(/src/Components/assets/images/Group\ 35.svg);
  background-color: #FF6738;
  background-repeat: no-repeat;
  background-position: center;
  width: 1rem;
  height: 1.5rem;
  background-size: 1rem 1.5rem;
  
}
#sideSearch{
  width:100%;
  height: 34.5rem;
  box-shadow:0rem 0.25rem 0.5rem  #00000029;
}
#search-area{
  display: flex;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
/* #search-area{
  margin-top: 2rem;
  margin-left: 1rem;
} */
#checkout-button-cart{
  color:#FF6738;
  font-weight: bolder;  
  align-self: center;
}
#Cart{
  display: grid;
  text-decoration: none;
  height:39rem;
  grid-template-rows: 4fr 35fr 8fr;
}
  #Cheading{
    text-align: center;
    box-shadow: 0rem 0.2rem 0rem #00000029;
    opacity: 80%;
    font-weight: 600;
    width: 90%;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 2rem;
    font-weight: bolder;
    font-size: x-large;
  }
  #Cdetails{
  overflow: scroll;
  font-size: small;
  padding:1rem;
  color: darkgray;
  font-style: italic;
  font-weight: bolder;
  }
  #TotalAmount{
    border: 0px;
    grid-auto-rows: 1fr 2fr;
    box-shadow: none;
    display: grid;
  }
  #Ctext{
    font-weight: bolder;
    font-size:large;
    box-shadow: 0rem 0.25rem 0.5rem #00000029;
    padding-left: 1rem;
  }
  #total{
    font-size: x-large;
    padding-left: 1rem;
    font-weight: bolder;
    box-shadow: 0rem 0.25rem 0.5rem #00000029;
  }

/* for styling the Cart details */
#CartValue{
  display: grid;
  grid-template-rows: 3fr 1fr;
  box-shadow: 0rem 0.1rem 0rem #00000029 ;
  height: 6rem;
  border: 0px;
  text-decoration: none;
}
#Cart-title{

  font-weight: 600;
  flex-direction: row;
  display: flex;
  color: #0f1317;
  font-style: normal;
  border: 0px;

}
#cart-image{
  margin-right: 0.3rem;
  margin-top: 0.4rem;
  margin-bottom: 0.3rem;
  width: 2rem;
  height: 2.2rem;
  background-color: #f2f2f2;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  flex-basis: 20%;

}
#values-name{
  flex-basis:80% ;
}
#cartTotal{
  font-style: normal;
  font-weight: bolder;
  text-align: end;
  margin-right: 1rem;
  color: #0F1317;
  font-size: x-large;
  margin-right: 1rem;
  margin-top: 2rem;
}

/* #search{
  border: rgb(7, 7, 6);
  /* display: grid; */
  /* margin-top: 0px;
}
#search input[type=text] {
  float: right;
  padding: 8px;
  border: black;
  margin-top: 8px;
  margin-right: 0rem;
  font-size: 17px;
  margin-left: 8rem;
}
#sideSearch{
  /* margin-top: 1rem;
  margin-bottom: 2rem; */
  /* width: 30rem;
  height: 45rem;
  display: grid;
  grid-template-columns: 3fr 1fr;
  border: 1px solid black;
  margin-left: 5rem;
  margin-right: 0rem;

} */
/* for styling the main body area */
.MainBody{
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 95rem;
  height: 45rem;
  display: grid;
  grid-template-columns: 3fr 1fr;
  border: 1px solid black;
  margin-left: 5rem;
  margin-right: 5rem;
}
#BodyMenu{
  display: flex;
  flex-direction: column;
  margin-left: 0.3rem;
  margin-right: 2.5rem;
  box-shadow: 0rem 0.25rem 0.5rem #00000029;
  margin-top: 2.5rem;
}
#bmenubar{
  display: flex;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0px;
  background-repeat: no-repeat;
}
#btext{
  flex-basis: 100%;
  font-weight:1500;
  text-decoration: black;
  font-weight: bolder;
  font-size: larger;
  
}
.sortArea{
  flex-basis: 100%;
  
  height: 3rem;

}
/* styling of body aea */
.Barea{
  border: 1px solid black;
  height: 7rem;
  margin: 0.2rem;
  /* width:100%; */
  border:1px solid #F4F4F4;
  border-radius: 0rem 0rem 0.4rem 0.4rem;
  box-shadow: 0rem 0.25rem 0.5rem #00000029;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
  padding-left: 1rem;

} 

#Bwishlist,#discount,#Badd-cart,#Bwishlist1{
  width: 100%;
  align-self: center;
}
#next-page{
  width: 100%;
  align-self: center;
}
#Bimage{
  padding-top: 2rem;
  margin-right: 0.1rem;
  background-color: #f2f2f2;
  flex-basis:8%;
  margin-right: 0.1rem;
  padding-bottom: 2rem;

}
#Btitle{
  flex-basis: 30%;
  height: contain;
  display: grid;
  grid-template-rows:2fr 1fr ;
}
#Bimg,#Bauthor,#Bcost-price{
  width: 100%;
  align-self: center;
}
#Bname{
  height: 100%;
  font-weight: bold;
}

#Bauthor{
  flex-basis: 18%;
  margin-left: 0.4rem;
  margin-right: 0.1rem;
  height: 2rem;
}
#Bwishlist{
  flex-basis: 3%;
  background-size: contain;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  background-repeat: no-repeat;
  background-image:url ;
  height: 2rem;
  background-image: url(./Components/assets/images/Path\ 28-1.svg);
}
#Bwishlist1{
  flex-basis: 3%;
  background-size: contain;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  background-repeat: no-repeat;
  background-image:url ;
  height: 2rem;
  background-image: url(./Components/assets/images/Path\ 28.svg);
}
#Bcost-price{
  font-weight: bold;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  
  opacity: 0.7;
  flex-basis: 7.5%;
  height: 2rem;
}
#Bdiscount{
  font-weight: bold;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  
  opacity: 0.7;
  flex-basis: 7.5%;
  height: 2rem;
  align-self: center;

}
#Bcourse{
  height: 100%;
  display: flex;
  flex-direction: row
;
}
#Btag{
  width: 4rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  border-radius: 0.2rem;
  text-align: center;
  padding-right: 0.2rem;
  padding-left : 0.2rem;
  font-size: 100%;
  background-color: #ff6738;
  height: 1.2rem;
}
#Badd-cart{
  flex-basis: 15%;
}
#next-page{
  flex-basis: 3%;
  height: 1.5rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-image:url(/src/Components/assets/images/Path\ 20-1.svg) ;
}
#add-items{
  border: none;
  text-align: center;
  margin-left: 0.5rem;
  margin-right: 0.1rem;
  cursor: pointer;
  background-color:#ff6738;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  text-decoration: none;
  text-align: center;
  border-radius: 0.3rem;
  text-decoration: none;
}
/* designing of mainlogo screen */
#logo-img
{
  background-color:#020f1b;
  margin-left: 5rem;
  margin-right: 5rem;
  height: 10rem;
  width: 95rem;
  background-image: url(/src/Components/assets/images/Mask\ Group\ 1.png);
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-repeat: no-repeat;
  background-size: 95rem 10rem;
  border-radius: 0.4rem;
}
.logo-text{
  padding-bottom: 0rem;

}
.logo-course{
  padding-top: 0rem;
}
.logo-text{
  margin-left: 3rem;
  padding: 1rem;
  font-size: xx-large;
  color: #f5f5f5;
  border: 0px;
  
}
.logo-course
{
  margin-left: 3rem;
  padding: 1rem;
  font-size: xx-large;
  color: #f5f5f5;
  border: 0px;
}
/* designing of top bar in website */

#logo{
  background-image: url(/src/Components/assets/images/Logo-2.png);
  width:95rem;
  height: 1.6rem;
  flex-basis: 79%;
  background-size: contain;
  background-repeat: no-repeat;
}

#couses{
  flex-basis: 7%;
  font-weight:1500;
  border: 0px;
  text-decoration:black;
}
#main-bar
{
  display: flex;
  flex-direction: row;
  background-color: rgb(241, 237, 237);
  box-shadow: 0rem 0.1rem 0.3 rem black;
  height: 1.5rem;
  width: full;
  padding: 1rem;
  border: 0px black;
  
}
#cart
{
  background-image: url(/src/Components/assets/images/shopping-cart.svg);
  flex-basis: 3%;
  height: 1.6rem;
  background-repeat: no-repeat;
}
#wishlist{
  border: 0px;
  flex-basis: 9%;
  margin-left: 3rem;
  font-weight: bolder;
  border :0px;
  margin-right:1rem;
}
#profile{
  background-image: url(/src/Components/assets/images/noun_profile_2068277.svg);
  height: 1.6rem;
  flex-basis: 3%;
  background-repeat: no-repeat;
  margin-left: 2rem;
}